<template>
    <!-- Table Container Card -->
    <div>
        <b-card-actions title="Filters" action-collapse collapsed>
            <div v-if="tableLoading" class="text-center">
                <b-spinner variant="primary" />
            </div>
            <div v-else>
                <b-row>
                    <b-col class="d-flex flex-sm-row flex-column align-items-center justify-content-between">
                        <div class="shop-select-first shop-select">
                            <v-select
                                v-if="shopOrderOptions"
                                v-model="shopFilter"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="shopOrderOptions"
                                :reduce="val => val.value"
                                placeholder="Select Shop"
                                @input="val => saveShopFilter(val)"
                            >
                                <template #selected-option="{ label }">
                                    <span v-if="Number.isNaN(shopFilter)" class="text-truncate overflow-hidden">{{
                                        label
                                    }}</span>
                                    <span v-else class="text-truncate overflow-hidden">{{
                                        shopOrderOptions.filter(option => option.value == shopFilter)[0].label
                                    }}</span>
                                </template>
                            </v-select>
                        </div>
                        <div class="status-select">
                            <v-select
                                v-model="statusFilter"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="statusOptions"
                                :reduce="val => val.value"
                                class="status-vselect mr-0"
                                placeholder="Select Status"
                                @input="val => saveStatusFilter(val)"
                            >
                                <template #selected-option="{ label }">
                                    <span class="text-truncate overflow-hidden">
                                        {{ label }}
                                    </span>
                                </template>
                            </v-select>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col class="d-flex flex-sm-row flex-column align-items-center justify-content-between">
                        <div class="shop-select">
                            <b-form-datepicker
                                id="datepicker-begin"
                                v-model="dateFilterVModelBegin"
                                @input="val => setDate(val, 'begin')"
                            />
                        </div>
                        <div class="status-select datepicker-container">
                            <b-form-datepicker
                                id="datepicker-end"
                                v-model="dateFilterVModelEnd"
                                class="status-vselect"
                                @input="val => setDate(val, 'end')"
                            />
                        </div>
                    </b-col>
                </b-row>
                <b-row cols="12" md="6">
                    <b-col class="filters-bottom-wrapper">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-form-checkbox v-if="!statusFilter" v-model="showOnHold" class="custom-control-warning">
                                Show On-Hold
                            </b-form-checkbox>
                        </div>
                        <b-button variant="danger" class="remove-filters-button" @click="removeFilters"
                            >Remove Filters</b-button
                        >
                    </b-col>
                </b-row>
            </div>
        </b-card-actions>
        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Entries</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block ml-50 mr-1"
                        />
                        <b-button variant="primary" :to="{ name: 'ecommerce-order-add' }" class="ml-1">
                            Add Order
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end invoice-filter-select-wrapper">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refOrderListTable"
                :items="fetchOrders"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
            >
                <!-- Column: Shop -->
                <template #cell(shop)="data" class="d-flex align-items-center justify-content-center">
                    <b-avatar
                        :id="`shop-avatar-${data.item.id}`"
                        size="32"
                        :src="data.item.avatar"
                        :text="avatarText(data.item.shopName)"
                        :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
                        :badge="data.item.flagged ? ' ' : null"
                        badge-offset="-0.3em"
                        badge-variant="warning"
                    />

                    <b-popover placement="top" triggers="hover" :target="`shop-avatar-${data.item.id}`">
                        <template #title>
                            <span>{{ data.item.shopName }}</span>
                        </template>
                        <div v-for="rep in data.item.representatives" :key="rep.id">
                            <span :class="representativeColor(rep.id)">
                                {{ rep.name }}
                            </span>
                        </div>
                    </b-popover>
                </template>

                <!-- Column: Customer -->
                <template #cell(customerNameSurname)="data">
                    <b-media vertical-align="center">
                        <small
                            ><b-link
                                :id="`id-order-${data.item.id}`"
                                :to="{
                                    name: 'ecommerce-order-preview',
                                    params: { id: data.item.id },
                                }"
                                class="font-weight-bold"
                            >
                                #{{ data.item.originId }}
                            </b-link>
                            <b-tooltip :target="`id-order-${data.item.id}`" triggers="hover">
                                <a :href="data.item.shopUrl" class="text-white"
                                    ><b>{{ data.item.shopName }}</b></a
                                >
                            </b-tooltip></small
                        >
                        <template #aside />
                        <b-link
                            :id="`id-order-${data.item.id}`"
                            :to="{
                                name: 'ecommerce-order-preview',
                                params: { id: data.item.id },
                            }"
                            class="font-weight-bold"
                        >
                            <span class="font-weight-bold d-block text-nowrap">
                                {{ data.item.customerNameSurname }}
                            </span>
                        </b-link>

                        <small class="text-muted">{{ data.item.email }}</small>
                    </b-media>
                </template>

                <template #cell(total)="data">
                    <span class="text-nowrap">
                        {{ data.item.total | formatCurrency }}
                    </span>
                </template>

                <!-- Column: Issued Date -->
                <template #cell(originCreateDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.originCreateDate | formatDate }}
                    </span>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                    <template>
                        <b-badge
                            :id="`id-order-${data.item.id}-status`"
                            pill
                            :variant="`light-${resolveOrderStatusVariantAndIcon(data.value).variant}`"
                            class="text-capitalize"
                        >
                            {{ resolveOrderStatus(data.value) }}
                        </b-badge>

                        <b-popover
                            v-if="Array.isArray(data.item.shipment) && data.item.shipment.length > 0"
                            :target="`id-order-${data.item.id}-status`"
                            triggers="hover"
                        >
                            <template #title>
                                <span>Shipping</span>
                            </template>
                            <div
                                v-for="(item, idx) in data.item.shipment"
                                :key="idx"
                                class="mt-50"
                                :class="idx === 0 ? 'mt-0' : 'mt-50'"
                            >
                                <span class="font-weight-bolder d-block text-nowrap text-uppercase">
                                    {{ item.tracking_provider | formatShipment }}
                                </span>
                                <small class="text-muted">{{ item.tracking_number }}</small>
                            </div>
                        </b-popover>
                    </template>
                </template>

                <!-- Column: LineItems -->
                <template #cell(lineItems)="data">
                    <div
                        v-if="
                            Array.isArray(data.item.itemImages.slice(0, 3)) &&
                            data.item.itemImages.slice(0, 3).length > 0
                        "
                        :id="`line-items-${data.item.id}`"
                        class="lazy-image-group"
                    >
                        <div
                            v-for="(imgSrc, index) in data.item.itemImages.slice(0, 3)"
                            :key="index"
                            class="d-block lazy-image-container"
                        >
                            <b-img-lazy :src="imgSrc" height="40" />
                        </div>
                    </div>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :id="`shop-row-${data.item.id}-sync-icon`" @click="syncOrder(data.item.id)">
                            <feather-icon icon="RefreshCwIcon" size="16" class="align-middle text-body" />
                            <span class="align-middle ml-50">Sync</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <b-link
                                :to="{
                                    name: 'ecommerce-order-edit',
                                    params: {
                                        id: data.item.id,
                                    },
                                }"
                                class="text-body"
                            >
                                <feather-icon
                                    :id="`order-row-${data.item.id}-preview-icon`"
                                    icon="EditIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                                <span class="align-middle ml-50">Edit</span>
                            </b-link>
                        </b-dropdown-item>
                        <b-dropdown-item
                            class="bg-blue"
                            @click="toggleFlag(data.item.id, data.item.originId, data.item.flagged)"
                        >
                            <feather-icon
                                :id="`order-row-${data.item.id}-flag-icon`"
                                color="warning"
                                icon="FlagIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                            <span class="align-middle ml-50">{{ data.item.flagged ? "Unflag" : "Flag" }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="updateRepresentative(data.item.id, data.item.originId, data.item.representatives)"
                        >
                            <feather-icon
                                :id="`order-row-${data.item.id}-rep-icon`"
                                icon="UserIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                            <span class="align-middle ml-50">{{
                                representativeState(data.item.representatives).exists ? "Unassign" : "Assign"
                            }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-row v-if="totalOrders === 0" class="mx-2 d-flex justify-content-center"
                ><span class="font-weight-bold">No Records Found</span></b-row
            >
            <div v-if="tableLoading" class="text-center my-1">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalOrders"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BAvatar,
    BImgLazy,
    BDropdown,
    BDropdownItem,
    BBadge,
    BPopover,
    BCard,
    BCol,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BFormInput,
    BSpinner,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
} from "bootstrap-vue";
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";
import { onUnmounted, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import useOrderList from "./useOrderList";

import orderStoreModule from "../orderStoreModule";
import { getUserData } from "@/auth/utils";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormDatepicker,
        BImgLazy,
        BFormInput,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BLink,
        BBadge,
        BCardActions,
        BPagination,
        BTooltip,
        BSpinner,
        BPopover,
        vSelect,
    },
    setup() {
        const toast = useToast();
        const userId = getUserData().id;
        const ECOMMERCE_ORDER_STORE_MODULE_NAME = "ecommerce-order";

        const dateFilterVModelBegin = ref("");
        const dateFilterVModelEnd = ref("");

        const representativeState = repArray => {
            const userIdExists = repArray.some(representative => representative.id === userId);

            if (userIdExists) {
                return {
                    variant: "danger",
                    text: "Remove Representative",
                    exists: true,
                };
            } else {
                return {
                    variant: "success",
                    text: "Assign Representative",
                    exists: false,
                };
            }
        };

        // log zort

        // Register module
        if (!store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
            store.registerModule(ECOMMERCE_ORDER_STORE_MODULE_NAME, orderStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ECOMMERCE_ORDER_STORE_MODULE_NAME)) {
                store.unregisterModule(ECOMMERCE_ORDER_STORE_MODULE_NAME);
            }
        });

        const resolveItemImage = item => {
            if (!item.image) {
                return;
            }
            if (item.image.src) {
                return item.image.src;
            } else if (item.image) {
                return item.image;
            } else {
                return;
            }
        };

        const imagesArray = lineItems => {
            let images = [];
            lineItems.forEach(item => {
                images.push(resolveItemImage(item));
            });
            return images;
        };

        const mapRepresentativeNames = representatives => {
            let names = [];
            representatives.forEach(item => {
                names.push(item.name);
            });
            let nameString = names.join(", ");
            return names.length > 0 ? nameString : "No Representatives";
        };

        const representativeColor = repId => {
            let repModulo = repId % 5;
            switch (repModulo) {
                case 0:
                    return "text-success";
                case 1:
                    return "text-info";
                case 2:
                    return "text-warning";
                case 3:
                    return "text-danger";
                case 4:
                    return "text-primary";
                default:
                    return "text-primary";
            }
        };

        const saveStatusFilter = status => {
            localStorage.statusFilter = status;
        };

        const saveShopFilter = shop => {
            localStorage.shopFilter = shop;
        };

        const removeFilters = async () => {
            dateFilterVModelEnd.value = null;
            dateFilterVModelBegin.value = null;
            dateFilterBegin.value = null;
            dateFilterEnd.value = null;
            statusFilter.value = null;
            shopFilter.value = null;
            await localStorage.removeItem("statusFilter");
            await localStorage.removeItem("shopFilter");
            refetchData();
        };

        const toggleFlag = async (id, originId, flagged) => {
            let action = flagged ? "Unflagged" : "Flagged";
            await store.dispatch(`ecommerce-order/toggleFlag`, id);
            toast({
                component: ToastificationContent,
                props: {
                    title: `${action} order #${originId}`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                },
            });
            refetchData();
        };

        const setDate = (dateStr, where) => {
            if (where === "begin") {
                dateFilterBegin.value = new Date(`${dateStr}`).toISOString();
            }
            if (where === "end") {
                dateFilterEnd.value = new Date(`${dateStr}`).toISOString();
            }
        };

        const statusOptions = [
            {
                label: "On-Hold",
                value: "on-hold",
            },
            {
                label: "Processing",
                value: "processing",
            },
            {
                label: "Partial Shipped",
                value: "partial-shipped",
            },
            {
                label: "Shipped",
                value: "completed",
            },
            {
                label: "Cancelled",
                value: "cancelled",
            },
            {
                label: "Pending",
                value: "pending",
            },
        ];
        const resolveOrderStatus = status => {
            switch (status) {
                case "on-hold":
                    return "On Hold";
                case "cancelled":
                    return "Cancelled";
                case "processing":
                    return "Processing";
                case "delivered":
                    return "Delivered";
                case "completed":
                    return "Shipped";
                case "partial-shipped":
                    return "Partial Shipped";
                case "pending":
                    return "Pending";
                default:
                    return "Unknown";
            }
        };

        const {
            fetchOrders,
            tableColumns,
            showOnHold,
            perPage,
            currentPage,
            totalOrders,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refOrderListTable,
            shopOrderOptions,
            statusFilter,
            dateFilterBegin,
            dateFilterEnd,
            shopFilter,
            tableLoading,
            refetchData,
            resolveOrderStatusVariantAndIcon,
            resolveClientAvatarVariant,
        } = useOrderList();

        const syncOrder = id => {
            store.dispatch("ecommerce-order/syncOrder", id).then(() => {
                refetchData();
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Order is synced successfully",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });
            });
        };

        const updateRepresentative = (orderId, originId, repArray) => {
            if (representativeState(repArray).exists) {
                store
                    .dispatch("ecommerce-order/removeRepresentative", orderId)
                    .then(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: `Unassigned you for order #${originId}`,
                                icon: "CheckCircleIcon",
                                variant: "success",
                            },
                        });
                    })
                    .then(() => refetchData())
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error removing representative.",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            } else {
                store
                    .dispatch("ecommerce-order/assignRepresentative", orderId)
                    .then(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: `Assigned you for order #${originId}`,
                                icon: "CheckCircleIcon",
                                variant: "success",
                            },
                        });
                    })
                    .then(() => refetchData())
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error assigning representative.",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    });
            }
        };

        return {
            fetchOrders,
            saveShopFilter,
            saveStatusFilter,
            tableColumns,
            showOnHold,
            perPage,
            currentPage,
            totalOrders,
            dataMeta,
            perPageOptions,
            searchQuery,
            toggleFlag,
            representativeColor,
            sortBy,
            dateFilterVModelBegin,
            dateFilterVModelEnd,
            isSortDirDesc,
            resolveItemImage,
            refOrderListTable,
            shopOrderOptions,
            statusFilter,
            updateRepresentative,
            shopFilter,
            tableLoading,
            setDate,
            dateFilterBegin,
            imagesArray,
            mapRepresentativeNames,
            dateFilterEnd,
            representativeState,
            refetchData,
            removeFilters,
            statusOptions,
            resolveOrderStatus,
            syncOrder,
            avatarText,
            resolveOrderStatusVariantAndIcon,
            resolveClientAvatarVariant,
        };
    },
};
</script>

<style lang="scss" scoped>
.lazy-image-group {
    display: flex;
    flex-direction: row;
    min-width: 120px;
}
.lazy-image-container {
    max-width: 300px;
}
.per-page-selector {
    width: 90px;
}
.shop-select {
    width: 100%;
}
.shop-select-first {
    padding-right: 0.2rem;
}
.status-select {
    width: 100%;
}
.status-vselect {
    margin-left: 1rem;
}

.datepicker-container {
    padding-right: 1rem;
}

.line-items-popover {
    width: 5rem;
    // put new elements right flex
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.line-items-popover-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.filters-bottom-wrapper {
    margin: 1rem auto auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.remove-filters-button {
    width: 25%;
    display: inline-block;
}

.invoice-filter-select {
    min-width: 180px;
    //   margin-right: 2%;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 80px;
    }
}

// x-small
@media screen and (max-width: 576px) {
    .invoice-filter-select {
        min-width: 90px;
        width: 100%;
        margin-top: 0.75rem;
    }
    .invoice-filter-select-wrapper {
        flex-wrap: wrap;
    }

    .filters-bottom-wrapper {
        margin: 1rem auto auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .remove-filters-button {
        width: 100%;
        margin-top: 1rem;
        display: inline-block;
    }
}

// small
@media screen and (max-width: 768px) {
    .invoice-filter-select {
        min-width: 100px;
        ::v-deep .vs__selected {
            width: 45px;
        }
    }

    .shop-select {
        width: 50%;
    }
    .status-select {
        width: 50%;
    }
}

// medium
@media screen and (max-width: 992px) {
    .invoice-filter-select {
        min-width: 120px;
        ::v-deep .vs__selected {
            width: 40px;
        }
    }

    .shop-select {
        width: 50%;
    }
    .status-select {
        width: 50%;
    }
}
// large
@media scren and (max-width: 1200px) {
    .invoice-filter-select {
        min-width: 140px;
        width: 140px;
        ::v-deep .vs__selected {
            width: 60px;
        }
    }

    .shop-select {
        width: 50%;
    }
    .status-select {
        width: 50%;
    }
}
// xlarge
@media screen and (min-width: 1400px) {
    .invoice-filter-select {
        // min-width: 180px;
        ::v-deep .vs__selected {
            width: 80px;
        }
    }
    .shop-select {
        width: 50%;
    }
    .status-select {
        width: 50%;
    }
}
// xxLarge
@media screen and (min-width: 1600px) {
    .invoice-filter-select {
        min-width: 170px;
        ::v-deep .vs__selected {
            width: 80px;
        }
    }
    .shop-select {
        width: 50%;
    }
    .status-select {
        width: 50%;
    }
}
@media screen and (max-width: 576px) {
    .shop-select {
        width: 100%;
    }
    .shop-select-first {
        padding-right: 0;
    }
    .status-select {
        width: 100%;
        margin-top: 1rem;
    }
    .status-vselect {
        margin-left: 0px;
    }
    .datepicker-container {
        padding-right: 0;
    }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
// center td
</style>
