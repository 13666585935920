import { computed, ref, watch } from "@vue/composition-api";

// Notification
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default function useOrderList() {
    // Use toast
    const toast = useToast();

    const refOrderListTable = ref(null);

    const showOnHold = ref(true);

    // Table Handlers
    const tableColumns = [
        {
            key: "shop",
            label: "Shop",
            sortable: false,
        },
        {
            key: "customerNameSurname",
            label: "Customer",
            sortable: false,
        },
        {
            key: "total",
            sortable: false,
            formatter: val => `$${val.toLocaleString()}`,
        },
        {
            key: "originCreateDate",
            label: "Issued Date",
            sortable: true,
        },
        {
            key: "status",
            sortable: false,
        },
        {
            key: "lineItems",
            label: "Items",
            sortable: false,
        },
        {
            key: "actions",
        },
    ];
    const perPage = ref(25);
    const totalOrders = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [25, 50, 100];
    const searchQuery = ref("");
    const shopFilter = ref(localStorage.getItem("shopFilter") || null);
    const sortBy = ref("originCreateDate");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(localStorage.getItem("statusFilter") || null);
    const shopOrderOptions = ref(null);
    const dateFilterBegin = ref(null);
    const dateFilterEnd = ref(null);
    const tableLoading = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0;

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalOrders.value,
        };
    });

    const refetchData = () => {
        refOrderListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter, showOnHold, shopFilter, dateFilterBegin, dateFilterEnd],
        () => {
            if (statusFilter.value === null) {
                localStorage.removeItem("statusFilter");
            }
            if (shopFilter.value === null) {
                localStorage.removeItem("shopFilter");
            }
            refetchData();
        },
    );

    const fetchOrders = (ctx, callback) => {
        store
            .dispatch("ecommerce-order/fetchOrders", {
                q: searchQuery.value,
                shopId: shopFilter.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                showOnHold: showOnHold.value,
                startDate: dateFilterBegin.value,
                endDate: dateFilterEnd.value,
            })
            .then(response => {
                const { orders, total, shopOptions } = response.data;
                totalOrders.value = total;
                shopOrderOptions.value = shopOptions;
                tableLoading.value = false;
                return orders;
            })
            .then(orders => {
                orders.forEach(order => {
                    order.itemImages = [];
                    order.lineItems.forEach(item => {
                        if (!item.image) {
                            return;
                        }
                        if (item.image.src) {
                            order.itemImages.push(item.image.src);
                        } else if (item.image) {
                            order.itemImages.push(item.image);
                        } else {
                            return;
                        }
                    });
                });
                return orders;
            })
            .then(orders => {
                callback(orders);
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching orders' list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveOrderStatusVariantAndIcon = status => {
        if (status === "on-hold") {
            return {
                variant: "warning",
                icon: "PieChartIcon",
            };
        }

        if (status === "processing") {
            return {
                variant: "success",
                icon: "CheckCircleIcon",
            };
        }

        if (status === "delivered") {
            return {
                variant: "info",
                icon: "ArrowDownCircleIcon",
            };
        }

        if (status === "completed") {
            return {
                variant: "primary",
                icon: "SaveIcon",
            };
        }

        if (status === "shipped") {
            return {
                variant: "secondary",
                icon: "SendIcon",
            };
        }

        if (status === "partial-shipped") {
            return {
                variant: "danger",
                icon: "InfoIcon",
            };
        }

        if (status === "pending") {
            return {
                variant: "info",
                icon: "InfoIcon",
            };
        }

        return {
            variant: "secondary",
            icon: "XIcon",
        };
    };

    const resolveClientAvatarVariant = status => {
        if (status === "on-hold") {
            return "warning";
        }

        if (status === "processing") {
            return "success";
        }

        if (status === "delivered") {
            return "info";
        }

        if (status === "completed") {
            return "primary";
        }

        if (status === "shipped") {
            return "secondary";
        }

        if (status === "partial-shipped") {
            return "danger";
        }

        if (status === "pending") {
            return "info";
        }

        return "secondary";
    };

    return {
        fetchOrders,
        tableColumns,
        showOnHold,
        perPage,
        currentPage,
        totalOrders,
        dataMeta,
        perPageOptions,
        dateFilterBegin,
        dateFilterEnd,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refOrderListTable,
        shopOrderOptions,
        statusFilter,
        shopFilter,
        tableLoading,
        resolveOrderStatusVariantAndIcon,
        resolveClientAvatarVariant,
        refetchData,
    };
}
