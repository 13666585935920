import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchOrders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/order", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchOrder(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/order/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchPaymentMethods(ctx, { shopId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/order/methods/${shopId}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            });
        },
        syncOrder(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/order/sync/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        toggleFlag(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/order/flag/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            })
        },
        syncActivities(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/order/sync/notes/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            });
        },
        addMessage(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/order/message/${payload.orderId}`, {
                        message: payload.message,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProducts(ctx, { query, shopId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`product?q=${query}&shopId=${shopId}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            });
        },
        fetchShops(ctx, { query }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`shop?q=${query}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            });
        },
        fetchCustomers(ctx, { query, shopId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customer/order/?q=${query}&shopId=${shopId}`)
                    .then(res => resolve(res.data))
                    .catch(err => reject(err));
            });
        },
        sendOrderEdit(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`order/${payload.id}`, {
                        payload,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createOrder(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("order/", payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateSource(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/order/source/${payload.orderId}`, {
                        source: payload.source,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        assignRepresentative(ctx, orderId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`order/user/assign/${orderId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        removeRepresentative(ctx, orderId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`order/user/remove/${orderId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    },
};
